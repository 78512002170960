<script setup lang="ts">
const { logout, hasFeature } = useAuth()
defineEmits(["show-search"])
const open = ref(false)
function toggleNav() {
  open.value = !open.value
}

async function logoutAndRedirect() {
  await logout()
}

const route = useRoute()
watch(
  () => route.path,
  () => {
    open.value = false
  },
)

const showSearchDialog = ref(false)
function search() {
  showSearchDialog.value = true
}
const showUserDetails = ref(false)
</script>
<template>
  <LayoutSlideOver v-model:open="showUserDetails">
    <template #title>
      <TypographyHeading :level="3">
        {{ $t("resilience_score.title") }}
      </TypographyHeading>
    </template>
    <UserScore />
  </LayoutSlideOver>
  <nav class="bg-slate-900">
    <SearchDialog v-model:open="showSearchDialog" />
    <div class="relative mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:px-8 lg:py-2.5">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div
            class="relative flex flex-shrink-0 flex-row items-center justify-center"
          >
            <NuxtLink to="/">
              <LogoSvg class="h-8" />
            </NuxtLink>
          </div>
          <div class="hidden lg:block">
            <div class="ml-6 flex items-baseline space-x-2">
              <NuxtLink
                to="/"
                active-class="bg-slate-700 text-white"
                class="rounded-md px-3 py-2 text-sm font-medium text-slate-300 hover:bg-slate-800 hover:text-white"
                exact
              >
                {{ $t("navigation.overview") }}
              </NuxtLink>
              <NuxtLink
                v-if="hasFeature('hub-videos')"
                to="/videos"
                active-class="bg-slate-700 text-white"
                class="rounded-md px-3 py-2 text-sm font-medium text-slate-300 hover:bg-slate-800 hover:text-white"
              >
                {{ $t("navigation.videos") }}
              </NuxtLink>
              <NuxtLink
                v-if="hasFeature('hub-courses')"
                to="/courses"
                active-class="bg-slate-700 text-white"
                class="rounded-md px-3 py-2 text-sm font-medium text-slate-300 hover:bg-slate-800 hover:text-white"
              >
                {{ $t("navigation.courses") }}
              </NuxtLink>
              <NuxtLink
                v-if="hasFeature('hub-simulations')"
                to="/simulations"
                active-class="bg-slate-700 text-white"
                class="rounded-md px-3 py-2 text-sm font-medium text-slate-300 hover:bg-slate-800 hover:text-white"
              >
                {{ $t("navigation.simulations") }}
              </NuxtLink>
              <NuxtLink
                v-if="hasFeature('hub-breaches')"
                to="/breaches"
                active-class="bg-slate-700 text-white"
                class="rounded-md px-3 py-2 text-sm font-medium text-slate-300 hover:bg-slate-800 hover:text-white"
              >
                {{ $t("navigation.breaches") }}
              </NuxtLink>
            </div>
          </div>
        </div>
        <div class="hidden space-x-3 lg:flex lg:items-center">
          <PointBadge class="cursor-pointer" @click="showUserDetails = true" />
          <FormButton
            v-if="hasFeature('hub-search')"
            variant="dark"
            data-testid="button_search"
            @click="search"
          >
            <SvgoSearch class="mr-2 size-5" />
            <span class="text-left text-sm font-medium lg:min-w-24">{{
              $t("search.placeholder")
            }}</span>
          </FormButton>
          <FormButton variant="dark" @click="logoutAndRedirect">
            <SvgoLogout class="size-5" />
          </FormButton>
        </div>
        <div class="-my-2 flex flex-row items-center space-x-3 lg:hidden">
          <PointBadge class="cursor-pointer" @click="showUserDetails = true" />
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md bg-slate-800 p-2 text-slate-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            :aria-expanded="open"
            @click="toggleNav"
          >
            <span class="sr-only">Open menu</span>
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100 origin-top-right"
      enter-from-class="transform opacity-0 scale-75"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75 origin-top-right"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-75"
      appear
    >
      <div
        v-if="open"
        class="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition lg:hidden"
      >
        <div
          class="divide-y-2 divide-slate-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="px-5 pb-6 pt-5">
            <div class="flex items-center justify-between">
              <NuxtLink to="/">
                <LogoSvg class="h-8" />
              </NuxtLink>
              <div class="-mr-2">
                <button
                  type="button"
                  class="inline-flex items-center justify-center rounded-md bg-white p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  @click="toggleNav"
                >
                  <span class="sr-only">Close menu</span>
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <NavItemMobile route="/">{{
                  $t("navigation.overview")
                }}</NavItemMobile>
                <NavItemMobile
                  v-if="hasFeature('hub-videos')"
                  :route="{ name: 'videos' }"
                >
                  {{ $t("navigation.videos") }}
                </NavItemMobile>
                <NavItemMobile
                  v-if="hasFeature('hub-courses')"
                  :route="{ name: 'courses' }"
                >
                  {{ $t("navigation.courses") }}
                </NavItemMobile>
                <NavItemMobile
                  v-if="hasFeature('hub-simulations')"
                  :route="{ name: 'simulations' }"
                >
                  {{ $t("navigation.simulations") }}
                </NavItemMobile>
                <NavItemMobile
                  v-if="hasFeature('hub-breaches')"
                  :route="{ name: 'breaches' }"
                >
                  {{ $t("navigation.breaches") }}
                </NavItemMobile>
                <NavItemMobile :route="{ name: 'logout' }">
                  {{ $t("navigation.logout") }}
                </NavItemMobile>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>
